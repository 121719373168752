<template>
  <div :class="['card', borderColor ? `border border-${borderColor}` : '']">
    <div class="p-3 card-body position-relative">
      <div class="row">
        <div class="col-12 text-start">          
          <div class="text-sm mb-1 text-uppercase font-weight-bold d-flex justify-content-between">
            {{ title }}
          </div>
          <h5 class="mb-0 font-weight-bolder">
            {{
              filters.currencyWithPrefix(
                isRealizado ?
                  itemAtual.realizado :
                  itemAtual.previsto)
            }}
          </h5>
          <span :class="`text-${percentage.color}`" class="mt-auto mb-0 text-sm text-end font-weight-bolder">
            <i :class="percentage.icon"></i> {{ percentage.value }}%
            <span class="font-weight-normal text-secondary">
              {{ percentage.label }}
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "FinanceiroItemCard",
  components: {
  },
  props: {
    isRealizado: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '***',
    },
    borderColor: {
      type: String,
      default: ''
    },
    itemAtual: {
      type: Object,
      default: () => { },
    },
    itemAnterior: {
      type: Object,
      default: () => { },
    }
  },
  data() {
    return {
      showMenu: false,
      percentage: {
        color: 'success',
        value: 0,
        label: '',
        icon: ''
      }
    }
  },
  mounted() {
    this.checkPercentage()
  },
  methods: {
    checkPercentage() {
      const { itemAtual, itemAnterior, isRealizado } = this;

      if (itemAtual && itemAnterior) {

        const valorAtual = isRealizado ? itemAtual.realizado : itemAtual.previsto;
        const valorAnterior = isRealizado ? itemAnterior.realizado : itemAnterior.previsto;

        let percentualDiferenca = ((1 - (valorAnterior / valorAtual)) * 100);

        if (Number.isNaN(percentualDiferenca)) {
          percentualDiferenca = 0;
        }

        this.percentage = {
          icon: percentualDiferenca >= 0 ? 'fas fa-arrow-up' : 'fas fa-arrow-down',
          value: (percentualDiferenca ?? 0).toFixed(2),
          color: percentualDiferenca >= 0 ? 'success' : 'danger',
          label: 'mês anterior'
        }
      }
    }
  },
  watch: {
    itemAtual: function () {
      this.checkPercentage();
    },
    isRealizado: function () {
      this.checkPercentage();
    },
  }

};
</script>
