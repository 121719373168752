<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-12">
        <receitas-despesas-mensal-card categoria="Receitas"/>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <receitas-despesas-mensal-card categoria="Despesas"/>
      </div>
    </div>
    
    <div class="mt-4 row">      
      <div class="mt-4 col-lg-12 mt-sm-0">
        <receitas-despesas-anual-chart />
      </div>
    </div>
  </div>
</template>

<script>
// import ReceitasPieChartCard from "./components/ReceitasPieChartCard.vue";
import ReceitasDespesasMensalCard from "./components/ReceitasDespesasMensalCard.vue";
import ReceitasDespesasAnualChart from "./components/ReceitasDespesasAnualChart.vue";

import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "DashboardFinanceiro",
  components: {
    // ReceitasPieChartCard,    
    ReceitasDespesasAnualChart,
    ReceitasDespesasMensalCard,
  },
  data() {
    return {
      
    };
  },
  mounted() {
    setTooltip(this.$store.state.bootstrap);    
  },
};
</script>
