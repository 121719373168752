<template>
  <div class="py-2 container-fluid">
    <nps-navigator v-model="npsIndex" :title="npsTitle" :min="0" :max="items.length - 1" />
    <div class="row">
      <totais-nps-card title="Avaliações" :count="data.totais.avaliacoes" :possiveis="data.totais.possiveis" />
      <nps-percentual-card :percentual="data.totais.nps" />
    </div>
    <div class="row">
      <div class="col-md-6 mt-sm-2">
        <pizza-nps-card title="Votos X Categoria" :series="data.avaliacoes" />
      </div>
      <div class="col-md-6 mt-sm-2">
        <pizza-nps-card title="% X Categoria" :series="data.percentuais" :is-percentual="true" />
      </div>
    </div>
    <div class="mt-2 row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Avaliações({{ avaliacoesFiltered.length }})</h5>
                <div class="d-flex justify-content-between mt-2">
                  <argon-button color="success" size="xs" :full-width="true"
                    :variant="status == 'promotor' ? 'contained' : 'outline'" class="me-2"
                    @click="setStatus('promotor')">
                    Promotores
                  </argon-button>
                  <argon-button color="warning" size="xs" :full-width="true"
                    :variant="status == 'neutro' ? 'contained' : 'outline'" class="me-2" @click="setStatus('neutro')">
                    Neutros
                  </argon-button>
                  <argon-button color="danger" size="xs" :full-width="true"
                    :variant="status == 'detrator' ? 'contained' : 'outline'" @click="setStatus('detrator')">
                    Detratores
                  </argon-button>
                </div>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto" v-if="avaliacoesFiltered.length > 0">
                  <button class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0" type="button" name="button"
                    @click="exportData()">
                    Exportar CSV
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-md-12">
                <label>Pesquisar Cliente</label>
                <input class="form-control text-uppercase" v-model="search" placeholder="Usuário, Empresa, CPF">
              </div>
            </div>
            <div class="table-responsive">
              <table id="cancelamentos-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th class="my-2 text-xs">Nome</th>
                    <th class="my-2 text-xs">Empresa</th>
                    <th class="my-2 text-xs">Data</th>
                    <th class="text-center my-2 text-xs">Nota</th>
                    <th class="text-center my-2 text-xs">Status</th>
                    <th class="text-center my-2 text-xs">Detalhes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item of avaliacoesFiltered" :key="item.id">
                    <td class="text-uppercase">
                      <span class="my-2 text-xs">
                        {{ item.usuario }}
                      </span>
                    </td>
                    <td>
                      <span class="my-2 text-xs">
                        {{ item.empresa }}
                      </span>
                    </td>
                    <td>
                      <span class="my-2 text-xs">
                        {{ filters.formatDate(item.data) }}
                      </span>
                    </td>
                    <td class="text-center">
                      <span class="my-2 text-xs font-weight-bold">
                        {{ item.avaliacao }}
                      </span>
                    </td>
                    <td>
                      <badge-status-nps :status="item.status" />
                    </td>
                    <td class="text-center">
                      <button type="button" class="btn btn-info btn-xs py-1 px-2" data-bs-toggle="modal"
                        data-bs-target="#modal-avaliacao-cliente" @click="setAvaliacao(item)">
                        Detalhes
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <table id="nps-table" class="table table-flush" :style="{ display: 'none' }">
    <thead class="thead-light">
      <tr>
        <th class="my-2 text-xs">Cód Empresa</th>
        <th class="my-2 text-xs">Nome Fantasia</th>
        <th class="my-2 text-xs">Usuário</th>
        <th class="my-2 text-xs">Data</th>
        <th class="text-center my-2 text-xs">Nota</th>
        <th class="text-center my-2 text-xs">Status</th>
        <th class="text-center my-2 text-xs">Observações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item of avaliacoesFiltered" :key="item.id">
        <td class="text-uppercase">
          <span class="my-2 text-xs">
            {{ item.empresa_id }}
          </span>
        </td>
        <td class="text-uppercase">
          <span class="my-2 text-xs">
            {{ item.empresa }}
          </span>
        </td>
        <td class="text-uppercase">
          <span class="my-2 text-xs">
            {{ item.usuario }}
          </span>
        </td>
        <td>
          <span class="my-2 text-xs">
            {{ filters.formatDate(item.data) }}
          </span>
        </td>
        <td class="text-center">
          <span class="my-2 text-xs font-weight-bold">
            {{ item.avaliacao }}
          </span>
        </td>
        <td>
          <badge-status-nps :status="item.status" />
        </td>
        <td>
          <span class="my-2 text-xs">
            {{ item.observacao }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
  <modal-avaliacao-cliente :avaliacao="avaliacao" />
</template>

<script>
import TotaisNpsCard from "./components/TotaisNpsCard.vue";
import NpsPercentualCard from "./components/NpsPercentualCard.vue";
import PizzaNpsCard from "./components/PizzaNpsCard.vue";
import NpsNavigator from "./components/NpsNavigator.vue";
import BadgeStatusNps from "./components/BadgeStatusNps.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ModalAvaliacaoCliente from "../../pages/components/ModalAvaliacaoCliente.vue";
import api from "@/services/api";
import { exportToCSV } from "@/utils/export";

export default {
  name: "Nps",
  components: {
    PizzaNpsCard,    
    BadgeStatusNps,
    TotaisNpsCard,
    NpsNavigator,
    ArgonButton,
    ModalAvaliacaoCliente,
    NpsPercentualCard,
  },
  data() {
    return {
      npsIndex: 0,
      status: '',
      search: '',
      items: [],
      avaliacao: null,
      avaliacoes: [],
      avaliacoesFiltered: [],
      data: {
        avaliacoes: [],
        percentuais: [],
        quantidades: [],
        totais: {
          avaliacoes: 0,
          possiveis: 0,
          percentual: 0,
          nps: 0,
        },
      },
    };
  },
  created() {
    this.fetchNps();
  },
  methods: {
    setStatus(status) {
      this.status = this.status == status ? '' : status;
    },
    setAvaliacao(item) {
      this.avaliacao = item;
    },
    fetchNps() {
      api.get(`/nps`).then(({ data }) => {
        this.items = data;
        this.npsIndex = this.items.length - 1;
      });
    },
    fetchAvaliacoes() {
      const nps = this.items[this.npsIndex];

      api.get(`/nps/avaliacoes/${nps.id}`)
        .then(({ data }) => {
          this.avaliacoes = data;
          this.filterAvaliacoes();
        });
    },
    fetchData() {
      const nps = this.items[this.npsIndex];

      this.status = '';
      this.avaliacoes = [];
      this.avaliacoesFiltered = [];

      api.get(`/nps/data/${nps.id}`)
        .then(({ data }) => {
          this.data = data;
          this.fetchAvaliacoes();
        });
    },
    filterAvaliacoes() {
      const { status, avaliacoes, search } = this;

      let items = [
        ...avaliacoes
      ];

      if (search) {
        items = items.filter(item => item.search.toLowerCase().indexOf(search.toLowerCase()) > -1);
      }

      if (status) {
        items = items.filter(item => item.status == status);
      }

      this.avaliacoesFiltered = items;
    },
    exportData() {
      const nps = this.items[this.npsIndex];
      exportToCSV('nps-table', `nps-${nps ? nps.id : '**'}.csv`);
    },
  },
  computed: {
    npsTitle() {
      const nps = this.items[this.npsIndex];

      return `NPS ${nps ? nps.id : '***'}`;
    },    
  },
  watch: {
    npsIndex: function () {
      this.fetchData();
    },
    status: function () {
      this.search = '';
      this.filterAvaliacoes();
    },
    search: function () {
      this.filterAvaliacoes();
    },
  }
};
</script>
