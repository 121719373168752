<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-0">{{ categoria }}</h6>
        <h5 class="mb-0 font-weight-bolder">
          {{ filters.currencyWithPrefix(totalGeral) }}
        </h5>
        <argon-switch v-model="isRealizado" label-class="text-sm text-dark"
          :input-class="isRealizado ? `bg-${color}` : ''">
          Realizado
        </argon-switch>
      </div>
    </div>
    <div class="pt-2 pb-0 card-body">
      <div class="row mb-3">
        <div class="d-flex justify-content-between">
          <button type="button" :class="['btn', `btn-outline-${color}`, 'btn-sm mb-0']" @click="previous()">
            <i class="fas fa-chevron-left"></i> Anterior
          </button>
          <h6 class="mb-2 text-uppercase">
            {{ filters.formatDate(currentMonth, 'MMMM - YYYY') }}
          </h6>
          <button type="button" :class="['btn', `btn-outline-${color}`, 'btn-sm mb-0']" @click="next()">
            Próximo <i class="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="mt-4 col-sm-3 mt-sm-0">
          <financeiro-item-card :title="totalMensal.atual.groups.first.title"
            :item-atual="totalMensal.atual.groups.first" :is-realizado="isRealizado"
            :item-anterior="totalMensal.anterior.groups.first" :border-color="color" />
        </div>
        <div class="mt-4 col-sm-3 mt-sm-0">
          <financeiro-item-card :title="totalMensal.atual.groups.second.title"
            :item-atual="totalMensal.atual.groups.second" :is-realizado="isRealizado"
            :item-anterior="totalMensal.anterior.groups.second" :border-color="color" />
        </div>
        <div class="mt-4 col-sm-3 mt-sm-0">
          <financeiro-item-card :title="totalMensal.atual.groups.third.title"
            :item-atual="totalMensal.atual.groups.third" :is-realizado="isRealizado"
            :item-anterior="totalMensal.anterior.groups.third" :border-color="color" />
        </div>
        <div class="mt-4 col-sm-3 mt-sm-0">
          <financeiro-item-card :title="totalMensal.atual.groups.fourth.title"
            :item-atual="totalMensal.atual.groups.fourth" :is-realizado="isRealizado"
            :item-anterior="totalMensal.anterior.groups.fourth" :border-color="color" />
        </div>
      </div>
    </div>
    <div class="card-footer py-2 text-right" v-if="totalGeral > 0">
      <router-link :to="{
          name: `${categoria} Mensal`,
          params: {
            categoria: categoria.toLowerCase(),
            mes: getMonth(),
          }
        }" :class="['btn', `btn-outline-${color}`, 'btn-sm', 'float-right']">
        Ver Mais ...
      </router-link>
    </div>
  </div>
</template>

<script>
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import FinanceiroItemCard from "./FinanceiroItemCard.vue";
import api from './../../../services/api'
import * as moment from 'moment';

export default {
  name: "ReceitasDespesasMensalCard",
  props: {
    categoria: {
      type: String,
      default: 'Receitas'
    },
  },
  components: {
    FinanceiroItemCard,
    ArgonSwitch,
  },
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      isRealizado: false,
      currentMonth: moment()
        .startOf('month')
        .toDate(),
      totalMensal: {
        atual: {
          groups: {
            first: {},
            second: {},
            third: {},
            fourth: {},
          }
        },
        anterior: {
          groups: {
            first: {},
            second: {},
            third: {},
            fourth: {},
          }
        },
      },
    };
  },
  methods: {
    next() {
      this.currentMonth = moment(this.currentMonth).add(1, 'month');
      this.fetchData();
    },
    previous() {
      this.currentMonth = moment(this.currentMonth).subtract(1, 'month');
      this.fetchData();
    },
    fetchData() {
      const dataReferencia = moment(this.currentMonth).format('YYYY-MM-DD');

      const { categoria } = this;

      api.get(`/dashboard-financeiro/${categoria.toLowerCase()}-mensal`, {
        params: {
          data: dataReferencia
        }
      }).then(response => {
        const { data } = response;

        this.totalMensal = data;

      }).catch(e => console.log(e));
    },
    detalhes() {
      this.$router.push({
        name: 'Receitas/Despesas Mensal',
        params: {
          categoria: this.categoria.toLowerCase(),
          mes: moment(this.currentMonth).format('YYYY-MM-DD')
        }
      });
    },
    getMonth() {
      return moment(this.currentMonth).format('YYYY-MM-DD')
    }

  },
  computed: {
    color() {
      return this.categoria == 'Receitas' ? 'success' : 'danger '
    },
    totalGeral() {
      const { isRealizado, totalMensal: { atual } } = this;

      const { groups } = atual;

      let total = 0;

      for (const key in groups) {
        const element = groups[key];

        if (typeof element == 'object') {
          total += isRealizado ? element.realizado : element.previsto
        }
      }

      return total;
    }
  },
};
</script>
