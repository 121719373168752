<template>
  <div class="card" :class="dark ? 'bg-gradient-dark' : ''">
    <div class="pb-0 card-header " :class="dark ? 'bg-transparent' : ''">
      <div class="d-lg-flex">
        <h6 :class="dark ? 'text-white' : ''">{{ title }}</h6>
        <div class="my-auto mt-4 ms-auto mt-lg-0">
          <div class="my-auto ms-auto">
            <button class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0" type="button" name="button"
              @click="exportData()">
              Exportar CSV
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="row mb-3">
        <div class="col-md-6">
          <receitas-despesas-total-card :color="color" :count="pagos" title="PAGOS" />
        </div>
        <div class="col-md-6">
          <receitas-despesas-total-card :color="color" :count="pendentes" title="PENDENTES" />
        </div>
      </div>
      <div class="timeline timeline-one-side" :data-timeline-axis-style="dark ? 'dashed' : 'dotted'">
        <timeline-receitas v-for="item of items" :key="item.data" :title="item.label" :previsto="true"
          :icon="{ component: 'fas fa-calendar', color }" :items="item.items" />
      </div>
    </div>
    <div class="table-responsive" :hidden="true">
      <table id="receitas-previstas-list" class="table table-flush">
        <thead class="thead-light">
          <tr>
            <th>Cliente</th>
            <th>Data</th>
            <th>Operação</th>
            <th>Status</th>
            <th class="text-center">
              Valor
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="item of items" :key="item.id">
            <tr v-for="it of item.items" :key="it.id">
              <td>
                {{ it.pessoa }}
              </td>
              <td>
                {{ it.data }}
              </td>
              <td class="text-center">
                {{ it.operacao.toUpperCase() }}
              </td>
              <td class="text-left">
                {{ it.status }}
              </td>
              <td class="text-right text-bg-dark text-bold">
                {{ filters.currencyWithPrefix(it.valor) }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import TimelineReceitas from "./TimelineReceitas.vue";
import { DataTable } from "simple-datatables";
import { tipoLancamentoReceita } from '@/utils/tipo-lancamentos';
import ReceitasDespesasTotalCard from "./ReceitasDespesasTotalCard.vue";

export default {
  name: "TimelineReceitasDespesas",
  components: {
    TimelineReceitas,
    ReceitasDespesasTotalCard,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
      default: "",
    },
    dark: {
      type: Boolean,
      default: false,
    },
    categoria: {
      type: String,
      default: 'receitas'
    },
  },
  mounted() {
    setTimeout(() => {
      this.setDataTable();
    }, 500);
  },
  data() {
    return {
      dataTable: null,
    };
  },
  methods: {
    setDataTable() {
      if (this.dataTable) {
        this.dataTable.destroy();
        this.dataTable = null;
      }

      this.dataTable = new DataTable("#receitas-previstas-list", {
        searchable: true,
        fixedHeight: false,
        paging: true,
        language: 'pt-BR',
        perPage: 10,
        labels: {
          perPage: "{select} registros por página",
          info: "Visualizando {start} até {end} de {rows} registros",
          placeholder: "Pesquisar",
        },
      });
    },
    exportData() {
      const { categoria } = this;

      const type = 'csv';

      let data = {
        type: type,
        filename: `${categoria}-previstas`,
      };

      if (type === "csv") {
        data.columnDelimiter = ";";
      }

      this.dataTable.export(data);
    },
    tipoLancamento(lancamento) {
      return tipoLancamentoReceita(lancamento)
    }
  },
  computed: {
    pagos() {
      let total = 0;

      this.items.forEach(item => {
        total += item.items.filter(item => item.status === 'PAGO').reduce((acc, item) => acc + Number(item.valor), 0);
      });

      return total;
    },
    pendentes() {
      let total = 0;

      this.items.forEach(item => {
        total += item.items.filter(item => item.status === 'PENDENTE').reduce((acc, item) => acc + Number(item.valor), 0);
      });

      return total;
    },
    color() {
      return this.categoria === 'receitas' ? 'info' : 'danger';
    }
  }
};
</script>