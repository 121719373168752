<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between mb-3">
        <h6 class="mb-0">
          Receitas x Despesas
        </h6>
        <argon-switch v-model="isRealizado" label-class="text-sm text-dark"
          :input-class="isRealizado ? `bg-success` : ''">
          Realizado
        </argon-switch>        
      </div>
      <div class="d-flex justify-content-between">
        <button type="button" class="btn btn-outline-success btn-xs mb-0" @click="previous()">
          <i class="fas fa-chevron-left"></i> Anterior
        </button>
        <h6 class="mb-2 text-uppercase text-bold">
          {{ currentYear }}
        </h6>
        <button type="button"
          :class="['btn', isNextVisible ? 'btn-outline-success' : 'btn-outline-secondary', 'btn-xs mb-0']" @click="next()"
          :disabled="!isNextVisible">
          Próximo <i class="fas fa-chevron-right"></i>
        </button>
      </div>
      <div class="d-flex align-items-center">
        <span class="badge badge-md badge-dot me-4">
          <i class="bg-success"></i>
          <span class="text-xs text-dark">Receitas</span>
        </span>
        <span class="badge badge-md badge-dot me-4">
          <i class="bg-danger"></i>
          <span class="text-xs text-dark">Despesas</span>
        </span>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas id="chart-receitas-despesas-anual" class="chart-canvas" height="300"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import * as moment from 'moment';
import api from './../../../services/api';

export default {
  name: "ReceitasDespesasAnualChart",
  components: {
    ArgonSwitch,
  },
  data() {
    return {
      isRealizado: false,
      isNextVisible: false,
      currentYear: moment().year(),
      datasets: [],
      anualChart: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    next() {
      this.currentYear++;
      this.fetchData();
    },
    previous() {
      this.currentYear--;
      this.fetchData();
    },
    fetchData() {
      const { currentYear, isRealizado } = this

      api.get('/dashboard-financeiro/total-anual', {
        params: {
          ano: currentYear,
          type: isRealizado ? 'R' : 'P'
        }
      }).then(response => {
        const { datasets } = response.data;

        this.setChartDatasets(datasets);

      }).catch(e => console.log(e));
    },
    setChartDatasets(data) {
      try {
        this.datasets = [];

        const datasets = [];

        const colors = ['#2dce89', '#f5365c'];

        const labels = [
          'Receitas',
          'Despesas',
        ];

        for (let i = 0; i < data.length; i++) {
          const item = data[i];

          datasets.push({
            label: labels.at(i),
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 2,
            pointBackgroundColor: colors.at(i),
            borderColor: colors.at(i),
            // eslint-disable-next-line no-dupe-keys
            borderWidth: 3,
            backgroundColor: 'transparent',
            fill: true,
            data: item,
            maxBarThickness: 6,
          });
        }

        this.datasets = datasets;

        this.buildChart(this.datasets);
      } catch (e) {
        console.log(e)
      }
    },
    buildChart(datasets) {
      var lineChart = document.getElementById('chart-receitas-despesas-anual').getContext("2d");

      let chartStatus = Chart.getChart('chart-receitas-despesas-anual');

      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      new Chart(lineChart, {
        type: "line",
        data: {
          labels: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
          datasets: datasets
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: true,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                color: "#9ca2b7",
                padding: 10,
              },
            },
          },
        },
      });
    }
  },
  watch: {
    currentYear: function (newYear) {

      const currentYear = moment().year();

      const isNextVisible = moment(currentYear).isAfter(newYear);

      this.isNextVisible = isNextVisible;
    },
    isRealizado: function () {
      this.fetchData();
    }
  }
};
</script>
