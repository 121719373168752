/**
=========================================================
* Vue Argon Dashboard 2 PRO - v3.0.1
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router/routes";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";
import introJs from "intro.js";
import moment from "moment";
import money from 'v-money3'
import "intro.js/minified/introjs.min.css";
import VueTheMask from 'vue-the-mask';
import '@vuepic/vue-datepicker/dist/main.css';

const appInstance = createApp(App)
    .use(introJs)
    .use(store)
    .use(router)
    .use(VueTilt)
    .use(VueTheMask)
    .use(VueSweetalert2)
    .use(ArgonDashboard)
    .use(money);

moment.locale('pt-BR')

appInstance.config.globalProperties.filters = {
    currencyUSD(value) {
        return '$' + value
    },
    currencyWithPrefix(value, decimals = 2) {
        if (isNaN(value)) {
            value = 0.0;
        }

        return Intl.NumberFormat('pt-BR', {
            currency: 'BRL',
            style: 'currency',
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
        }).format(value);
    },
    currency(value, decimals = 2) {
        if (isNaN(value)) {
            value = 0.0;
        }
        const valueFormat =
            Intl.NumberFormat('pt-BR', {
                currency: 'BRL',
                style: 'currency',
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals,
            }).format(value);

        return valueFormat.slice(2);

    },
    percentage(value, decimals = 0) {

        const valueFormat = Number(value ?? '0.00');

        return `${valueFormat.toFixed(decimals)}%`;

    },
    formatDate(date, format = 'DD/MM/YYYY') {
        return moment(date).format(format);
    },
    formatDateTime(date, format = 'DD MMM YY HH:mm') {
        return moment(date).format(format);
    },
    formatDocument(value = '') {
        const cnpjCpf = (value ?? '').replace(/\D/g, '');

        if (cnpjCpf.length === 11) {
            return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
        }

        return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
    },
    thousandsToInt(value) {
        if (value >= 1000000) {
            return `${(value / 1000000).toFixed(1)}M`;
        }

        if (value >= 1000) {
            return `${(value / 1000).toFixed(1)}k`;
        }

        return value.toFixed(1)

    }
};

appInstance.mount("#app");
