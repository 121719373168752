<template>
    <div class="table-responsive">
        <table :id="tableId" class="table table-flush">
            <thead class="thead-light">
                <tr>
                    <th v-for="column of columns" :key="column.key">
                        {{ column.name }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) of data" :key="index">
                    <!-- eslint-disable vue/no-v-html -->
                    <td v-for="column of columns" :key="`${column.key}_${index}`" :class="column.class">
                        <div v-if="column.key == 'action'" v-html="item[column.key]" />
                        <span v-else>
                            {{ item[column.key] }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import * as uuid from 'uuid';
import { DataTable } from "simple-datatables";

export default {
    name: "DataTable",
    props: {
        data: {
            type: Array,
            required: true
        },
        columns: {
            type: Array,
            required: true
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            tableId: `table-${uuid.v4()}`,
            dataTable: null
        };
    },
    methods: {
        setDataTable() {
            if (this.dataTable) {
                this.dataTable.destroy();
                this.dataTable = null;
            }

            this.dataTable = new DataTable(`#${this.tableId}`, {
                searchable: true,
                fixedHeight: false,
                paging: true,
                language: 'pt-BR',
                perPage: 10,
                labels: {
                    perPage: "{select} registros por página",
                    info: "Visualizando {start} até {end} de {rows} registros",
                    placeholder: "Pesquisar",
                },
            });

        },
        exportData() {
            const type = 'csv';

            let data = {
                type: type,
                filename: `data-${this.vertical}`,
            };

            if (type === "csv") {
                data.columnDelimiter = ";";
            }

            this.dataTable.export(data);
        },
    },
    watch: {
        data: function () {
            setTimeout(() => {
                this.setDataTable();
            }, 100);
        }
    }
}
</script>
<style lang="scss" scoped></style>