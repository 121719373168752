<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="mx-auto col-8">
        <div class="card">
          <div class="card-header">
            <h6 class="mb-0">{{ id == 'new' ? 'Nova reajuste' : 'Editar reajuste' }}</h6>
            <p class="mb-0 text-sm">Criar/Editar reajuste</p>
          </div>
          <div class="card-body">
            <form role="form" @submit.prevent="onSubmit">
              <div class="row mb-1">
                <div class="col-md-6">
                  <label>Data</label>
                  <datepicker v-model="v$.reajuste.data.$model" minimum-view="day"
                    class="form-control text-bold text-center" :locale="locale" input-format="dd/MM/yyyy" />
                  <div class="input-errors" v-for="(error, index) of v$.reajuste.data.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label>Operação</label>
                  <select class="form-control" v-model="v$.reajuste.operacao.$model" disabled>
                    <option :value="null">Selecione a Operação...</option>
                    <option v-for="operacao of operacoes" :key="operacao.id" :value="operacao.id">
                      {{ operacao.descricao }}
                    </option>
                  </select>
                  <div class="input-errors" v-for="(error, index) of v$.reajuste.operacao.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-md-6">
                  <label>Valor</label>
                  <money3 v-model="v$.reajuste.valor.$model" class="form-control text-right" v-bind="config" />
                  <div class="input-errors" v-for="(error, index) of v$.reajuste.valor.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label>Valor Consultoria</label>
                  <money3 v-model="v$.reajuste.valorConsultoria.$model" class="form-control text-right"
                    v-bind="config" />
                  <div class="input-errors" v-for="(error, index) of v$.reajuste.valorConsultoria.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="row mb-1">
                <div class="col-md-12">
                  <label>Cliente</label>
                  <select class="form-control" v-model="v$.reajuste.cliente.$model">
                    <option :value="null">Selecione um cliente...</option>
                    <option v-for="cliente of clientes" :key="cliente.id" :value="cliente">
                      {{ cliente.nomeFantasia ?? cliente.razaoSocial }} - {{ filters.formatDocument(cliente.documento)
                      }}
                    </option>
                  </select>
                  <div class="input-errors" v-for="(error, index) of v$.reajuste.cliente.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>

                </div>
              </div>
              <div class="d-flex justify-content-between">
                <back-button />
                <submit-button />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, helpers, minValue } from '@vuelidate/validators'
import { successToast, errorToast } from '@/utils/alerts';
import Datepicker from 'vue3-datepicker'
import api from '@/services/api'
import { ptBR } from 'date-fns/locale'
import { MONEY_CONFIG } from '@/utils/number'
import moment from 'moment';
import BackButton from '@/components/BackButton.vue';
import SubmitButton from '@/components/SubmitButton.vue';

export default {
  name: "Upsell",
  components: {
    Datepicker,
    BackButton,
    SubmitButton
  },
  created() {
    this.fetchClientes();

    this.id = this.$route.params.id;

    if (this.id == 'new') {
      return;
    }

    this.fetchData();
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      id: null,
      locale: ptBR,
      config: MONEY_CONFIG,
      clientes: [],
      operacoes: [
        { id: 'UPSELL', descricao: 'UPSELL' },
        { id: 'DOWNSELL', descricao: 'DOWNSELL' },
        { id: 'REAJUSTE_ANUAL', descricao: 'REAJUSTE ANUAL' },
      ],
      reajuste: {
        id: null,
        data: new Date(),
        valor: 0,
        valorConsultoria: 0,
        cliente: null,
        operacao: null
      }
    }
  },
  validations() {
    return {
      reajuste: {
        data: {
          required: helpers.withMessage('Data é obrigatória', required),
        },
        valor: {
          required: helpers.withMessage('Valor é obrigatório', required),
          minValue: minValue(0.00),

        },
        valorConsultoria: {
          required: helpers.withMessage('Valor é obrigatório', required),
          minValue: minValue(0.00),
        },
        cliente: {
          required: helpers.withMessage('Cliente é obrigatório', required),
        },
        operacao: {
          required: helpers.withMessage('Operação é obrigatória', required),
        }
      },
    }
  },
  methods: {
    fetchClientes() {
      api.get(`/clientes/list`)
        .then(({ data }) => {
          this.clientes = data;
        })
        .catch(e => console.log(e));
    },
    fetchData() {
      const { id } = this;

      api.get(`/upsells/${id}`)
        .then(({ data }) => {
          const date = moment(data.data).toDate();

          this.reajuste = {
            ...data,
            data: date
          };
        })
        .catch(e => console.log(e));
    },
    async onSubmit() {
      const isFormValid = await this.v$.$validate();

      if (!isFormValid) {
        return;
      }

      try {
        const payload = {
          ...this.reajuste
        }

        await (this.id == 'new' ?
          api.post('/upsells', payload) :
          api.patch(`/upsells/${this.id}`, payload));

        successToast('Reajuste salvo com sucesso.');

        setTimeout(() => {
          this.$router.back();
        }, 300);

      } catch (e) {
        console.log(e);
        const { data } = e.response;
        const message = data ? data.message : e.message;
        errorToast(message)
      }
    },
  }
};
</script>