<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Metas</h5>
                <p class="mb-0 text-sm">
                  Metas comerciais
                </p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link :to="{ name: 'Meta', params: { id: 'new' } }">
                    <button class="mt-1 mb-0 me-2 btn btn-outline-success btn-sm mt-sm-0" type="button" name="button">
                      +&nbsp; Nova Meta
                    </button>
                  </router-link>
                  <!-- <button class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0" type="button" name="button"
                    @click="exportData()">
                    Exportar CSV
                  </button> -->
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table id="metas-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th class="text-center">Mês</th>
                    <th class="text-right">MRR</th>
                    <th class="text-right">ARR</th>
                    <th class="text-right">Consultoria</th>
                    <th class="text-right">Churn</th>
                    <th class="text-right">Total</th>
                    <th class="text-center">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="meta of metas" :key="meta.id">
                    <td class="text-uppercase text-center">
                      <span class="my-2 text-sm text-bold text-dark">
                        {{ filters.formatDate(meta.data, 'MMM YYYY') }}
                      </span>
                    </td>
                    <td class="text-right">
                      <span class="my-2 text-sm text-dark text-bold">
                        {{ filters.currency(meta.valorMrr) }}
                      </span>
                    </td>
                    <td class="text-right">
                      <span class="my-2 text-sm text-dark text-bold">
                        {{ filters.currency(meta.valorArr) }}
                      </span>
                    </td>
                    <td class="text-right">
                      <span class="my-2 text-sm text-dark text-bold">
                        {{ filters.currency(meta.valorConsultoria) }}
                      </span>
                    </td>
                    <td class="text-right">
                      <span class="my-2 text-sm text-dark text-bold">
                        {{ filters.currency(meta.valorChurn) }}
                      </span>
                    </td>
                    <td class="text-right">
                      <span class="my-2 text-sm text-dark text-bold">
                        {{ filters.currency(meta.valorTotal) }}
                      </span>
                    </td>
                    <td class="text-center">
                      <router-link :to="{
                        name: 'Meta', params: {
                          id: meta.id
                        }
                      }" class="btn btn-xs btn-primary">
                        Editar
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from './../../services/api';

export default {
  name: "Metas",
  components: {
  },
  data() {
    return {
      metas: [],
      metasFiltered: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      api.get(`/metas`)
        .then(response => {
          const { data } = response;

          this.metas = data;
          this.metasFiltered = data;
        })
        .catch(e => console.log(e));
    },
    exportData() {
      const type = 'csv';

      let data = {
        type: type,
        filename: `metas-${this.vertical}`,
      };

      if (type === "csv") {
        data.columnDelimiter = ";";
      }

      this.dataTable.export(data);
    },
  },
  watch: {
    search: function () {
      this.filterModules();
    }
  }
};
</script>
