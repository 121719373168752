<template>
	<div class="card card-contratos-ativos">
		<div class="pb-0 card-header mb-0">
			<header-months :current-month="currentMonth" :on-change-date="onChangeDate" />
		</div>
		<div class="p-1 card-body">
			<div class="row">
				<div class="my-auto col-12 text-center">
					<div class="numbers">
						<p class="mb-0 text-sm text-secondary text-capitalize font-weight-bold opacity-7">
							Contratos Ativos -
							<span class="text-dark font-weight-bolder">
								{{ total.quantidade }}
							</span>
						</p>
						<h3 class="mb-0 text-dark font-weight-bolder">
							{{ total ? filters.currencyWithPrefix(total.total) : 0 }}
						</h3>
						<div v-if="total"
							class="mb-0 text-sm d-flex align-items-center justify-content-between text-capitalize font-weight-bold">
							<div>
								<span class="text-dark">
									MRR({{ total.quantidadeMrr }}):
								</span>
								<span class="text-dark font-weight-bolder">
									{{ filters.currency(total.totalMrr) }}
								</span>
							</div>
							<div v-if="isCurrentMonth">
								<span class="text-info">
									CONGELADOS({{ total.quantidadeCongelado }}):
								</span>
								<span class="text-info font-weight-bolder">
									{{ filters.currency(total.totalCongelado) }}
								</span>
							</div>
							<div>
								<span class="text-dark">
									ARR({{ total.quantidadeArr }}):
								</span>
								<span class="text-dark font-weight-bolder">
									{{ filters.currency(total.totalArr) }}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import HeaderMonths from './HeaderMonths.vue';

export default {
	name: "TotalContratosAtivosCard",
	components: {
		HeaderMonths
	},
	props: {
		total: {
			type: Object,
			default: () => { }
		},
		onChangeDate: {
			type: Function,
			required: true
		},
		currentMonth: {
			type: Date,
			required: true
		}
	},
	computed: {
		isCurrentMonth() {
			return this.currentMonth.getMonth() === new Date().getMonth();
		}
	}
};
</script>
<style lang="scss" scoped>
.card-contratos-ativos {
	.card-body {
		padding: 0.5em 1.0rem !important
	}
}
</style>
