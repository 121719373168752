<template>
  <div class="container-fluid py-2">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0">
            <h5 class="mb-0">
              Informações da Carteira
            </h5>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <clientes-counter-card :count="statusClientes.ativos" title="Ativos" :selected="isStatusSelected('A')"
                @click="filterStatus('A')" />
              <clientes-counter-card :count="statusClientes.inativos" title="Inativos" :selected="isStatusSelected('I')"
                @click="filterStatus('I')" />
              <clientes-counter-card :count="statusClientes.implantacao" title="Implantaçao"
                :selected="isStatusSelected('P')" @click="filterStatus('P')" col-lg-size="col-lg-2" />
              <clientes-counter-card :count="statusClientes.congelados" title="Congelados"
                :selected="isStatusSelected('C')" @click="filterStatus('C')" col-lg-size="col-lg-2" />
              <clientes-counter-card :count="statusClientes.bloqueados" title="Bloqueados"
                :selected="isStatusSelected('C')" @click="filterStatus('B')" col-lg-size="col-lg-2" />
            </div>
            <div class="row mt-2">
              <clientes-counter-card :count="totalClientes.hotel" title="Hotel" :selected="isVerticalSelected('2')"
                @click="filterVertical('2')" />
              <clientes-counter-card :count="totalClientes.motel" title="Motel" :selected="isVerticalSelected('1')"
                @click="filterVertical('1')" />
              <clientes-counter-card :count="totalClientes.restaurante" title="Restaurante"
                :selected="isVerticalSelected('4')" @click="filterVertical('4')" />
              <clientes-counter-card :count="totalClientes.condominio" title="Condominio"
                :selected="isVerticalSelected('3')" @click="filterVertical('3')" />
            </div>
            <div class="row mt-2" v-if="vertical == 2">
              <clientes-counter-card v-for="item of portes" :count="item.count()" :title="item.name"
                :selected="item.isSelected()" :key="item.id" @click="item.onClick" />
            </div>
            <div class="row mt-2" v-if="vertical == 2">
              <clientes-counter-card :count="totalClientes.modulosConferidos" title="Módulos Conferidos"
                :selected="isModulosConferidos(true)" @click="filterModulosConferidos(true)" />
              <clientes-counter-card :count="totalClientes.modulosNaoConferidos" title="Módulos Não Conferidos"
                :selected="isModulosConferidos(false)" @click="filterModulosConferidos(false)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">
                  Clientes({{ clientesFiltered.length }})
                </h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link :to="{ name: 'Cliente', params: { id: 'new' } }">
                    <button class="mt-1 mb-0 btn btn-outline-success btn-sm mt-sm-0" type="button" name="button">
                      +&nbsp; Novo Cliente
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-md-12">
                <label>Pesquisar Cliente</label>
                <input class="form-control text-uppercase" v-model="search" placeholder="Nome Fantasia, CPF/CNPJ">
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-12" v-for="cliente of clientesFiltered" :key="cliente.id">
                <cliente-card :cliente="cliente" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientesCounterCard from "./components/ClientesCounterCard.vue";
import ClienteCard from './components/ClienteCard.vue'
import api from '@/services/api';

export default {
  name: "Clientes",
  components: {
    ClienteCard,
    ClientesCounterCard,
  },
  data() {
    return {
      clientes: [],
      clientesFiltered: [],
      status: 'A',
      porte: '',
      vertical: '',
      search: '',
      modulosConferidos: null,
      params: {
        nome: '',
        status: 'T',
        vertical: 'T'
      },
      portes: [
        { id: 1, name: 'Basic', count: () => this.totalClientes.basic, isSelected: () => this.isPorteSelected(1), onClick: () => this.filterPorte(1) },
        { id: 2, name: 'Starter', count: () => this.totalClientes.starter, isSelected: () => this.isPorteSelected(2), onClick: () => this.filterPorte(2) },
        { id: 3, name: 'Pro', count: () => this.totalClientes.pro, isSelected: () => this.isPorteSelected(3), onClick: () => this.filterPorte(3) },
        { id: 4, name: 'Enterprise', count: () => this.totalClientes.enterprise, isSelected: () => this.isPorteSelected(4), onClick: () => this.filterPorte(4) },
      ],
      statusClientes: {
        ativos: 0,
        inativos: 0,
        implantacao: 0,
        congelados: 0,
        bloqueados: 0
      },
      totalClientes: {
        starter: 0,
        basic: 0,
        pro: 0,
        enterprise: 0,
        hotel: 0,
        motel: 0,
        restaurante: 0,
        condominio: 0,
        modulosConferidos: 0,
        modulosNaoConferidos: 0,
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;

      api.get(`/clientes`)
        .then(response => {
          const { data } = response;

          this.clientes = data;
          this.setStatusClientes();
          this.filterClientes();
          this.setTotalClientes();
        })
        .catch(e => console.log(e));
    },
    setStatusClientes() {
      const { clientes } = this;

      let ativos = 0;
      let inativos = 0;
      let implantacao = 0;
      let congelados = 0;
      let bloqueados = 0;

      this.totalClientes = {
        basic: 0,
        starter: 0,
        pro: 0,
        enterprise: 0,
      }

      for (const cliente of clientes) {
        const { ativo, status } = cliente;

        if (ativo) {
          ativos++;
        } else {
          inativos++
        }

        switch (status) {
          case 0:
            implantacao++;
            break;
          case 2:
            bloqueados++;
            break;
          case 3:
            congelados++;
            break;
        }
      }

      this.statusClientes = {
        ativos,
        inativos,
        implantacao,
        congelados,
        bloqueados
      }
    },
    formatFloat(value) {
      return this.filters.currency(value);
    },
    exportData() {
      const type = 'csv';

      let data = {
        type: type,
        filename: `clientes-${this.vertical}`,
      };

      if (type === "csv") {
        data.columnDelimiter = ";";
      }

      //this.dataTable.export(data);
    },
    onSetStatus(status) {
      this.status = status;

      this.filterModules();
    },
    filterClientes() {
      const { status, search, porte, vertical, modulosConferidos } = this;

      let items = [
        ...this.clientes
      ];

      switch (status) {
        case 'A': items = this.clientes.filter(item => item.ativo);
          break;
        case 'I': items = this.clientes.filter(item => !item.ativo);
          break;
        case 'P': items = this.clientes.filter(item => Number(item.status) == 0);
          break;
        case 'C': items = this.clientes.filter(item => Number(item.status) == 3);
          break;
        case 'B': items = this.clientes.filter(item => Number(item.status) == 2);
          break;
      }

      if (search) {
        items = items.filter(item => item.search.toLowerCase().indexOf(search.toLowerCase()) > -1);
      }

      if (vertical) {
        items = items.filter(item => item.vertical == vertical);
      }

      if (porte && vertical == 2) {
        items = items.filter(item => item.plano ? item.plano.id == porte : false);
      }

      if (modulosConferidos != null && vertical == 2) {
        items = items.filter(item => item.modulosConferidos == modulosConferidos);
      }

      this.clientesFiltered = items;

    },
    filterStatus(status) {
      this.status = this.status == status ? 0 : status;

      this.porte = '';
      this.vertical = '';

      this.filterClientes();
      this.setTotalClientes();
    },
    isStatusSelected(status) {
      return this.status == status;
    },
    filterPorte(porte) {
      this.porte = this.porte == porte ? 0 : porte;

      this.modulosConferidos = null;

      this.filterClientes();
      this.setTotalModulosConferidos();
    },
    isPorteSelected(porte) {
      return this.porte == porte;
    },
    filterVertical(vertical) {
      this.vertical = this.vertical == vertical ? 0 : vertical;
      this.porte = '';

      this.filterClientes();
      this.setTotalModulosConferidos();
    },
    isVerticalSelected(vertical) {
      return this.vertical == vertical;
    },
    setTotalModulosConferidos() {
      const { clientesFiltered } = this;

      this.totalClientes.modulosConferidos = 0;
      this.totalClientes.modulosNaoConferidos = 0;

      for (const cliente of clientesFiltered) {
        const { modulosConferidos } = cliente;

        modulosConferidos ? this.totalClientes.modulosConferidos++ : this.totalClientes.modulosNaoConferidos++;
      }
    },
    setTotalClientes() {
      const { clientesFiltered } = this;

      this.totalClientes = {
        basic: 0,
        starter: 0,
        pro: 0,
        enterprise: 0,
        hotel: 0,
        motel: 0,
        restaurante: 0,
        condominio: 0,
        modulosConferidos: 0,
        modulosNaoConferidos: 0,
      }

      for (const cliente of clientesFiltered) {
        const { plano, vertical } = cliente;

        const planoId = plano ? plano.id : 0;

        if (vertical == 2) {
          switch (planoId) {
            case 2: this.totalClientes.starter++;
              break;
            case 3: this.totalClientes.pro++;
              break;
            case 4: this.totalClientes.enterprise++;
              break;
            default:
              this.totalClientes.basic++;
              break;
          }
        }

        switch (vertical) {
          case 1:
            this.totalClientes.motel++;
            break;
          case 2:
            this.totalClientes.hotel++;
            break;
          case 3:
            this.totalClientes.condominio++;
            break;
          case 4:
            this.totalClientes.restaurante++;
            break;
        }
      }
    },
    totalConferidos(value) {
      return this.clientesFiltered.filter(item => item.modulosConferidos == value).length;
    },
    isModulosConferidos(value) {
      return this.modulosConferidos == value;
    },
    filterModulosConferidos(value) {
      this.modulosConferidos = this.modulosConferidos == value ? null : value;

      this.filterClientes();
    }
  },
  watch: {
    search: function () {
      this.filterClientes();
    },
  }
};
</script>
<style lang="scss">
.btn {
  margin-bottom: 0 !important;
}
</style>
