<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Contratos</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <button class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0" type="button" name="button"
                    @click="exportData()">
                    Exportar CSV
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table id="contratos-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Cliente</th>
                    <th>Tipo</th>
                    <th>Status</th>
                    <th>Documento</th>
                    <th>Data Assinatura</th>
                    <th>ID</th>
                    <th>Link</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="contrato of contratos" :key="contrato.id">
                    <td class="text-uppercase">
                      <span class="my-2 text-xs">
                        {{ contrato.nome }}
                      </span>
                    </td>
                    <td class="text-uppercase">
                      <badge-tipo-contrato :tipo-contrato="contrato.tipo" :size="xs" />
                    </td>
                    <td>
                      <span :class="['badge', contrato.status == 'closed' ? 'badge-success' : 'badge-danger', 'badge-xs']">
                        {{ contrato.status }}
                      </span>
                    </td>
                    <td>
                      <span class="my-2 text-xs">
                        {{ filters.formatDocument(contrato.documento) }}
                      </span>
                    </td>
                    <td>
                      <span class="badge badge-primary badge-xs">
                        {{ filters.formatDate(contrato.dataContrato) }}
                      </span>
                    </td>
                    <td>
                      <span class="text-xs">
                        {{ contrato.clickSignId }}
                      </span>
                    </td>
                    <td class="text-center">
                      <a target="_blank" :href="contrato.path">
                        <i class="ni ni-single-copy-04 text-sm text-info text-gradient opacity-10" aria-hidden="true">
                        </i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BadgeTipoContrato from "./components/BadgeTipoContrato.vue";
import { DataTable } from "simple-datatables";
import api from './../../services/api';

export default {
  name: "ProductsList",
  components: {
    BadgeTipoContrato
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      categoria: 'Contratos',
      total: 0,
      contratos: [],
      dataTable: null,
    }
  },
  methods: {
    fetchData() {

      api.get(`/contratos`).then(response => {
        const { data } = response;

        this.contratos = data;

      }).catch(e => console.log(e));
    },
    setDataTable() {
      if (this.dataTable) {
        this.dataTable.destroy();
        this.dataTable = null;
      }

      this.dataTable = new DataTable("#contratos-list", {
        searchable: true,
        fixedHeight: false,
        paging: true,
        language: 'pt-BR',
        perPage: 10,
        labels: {
          perPage: "{select} registros por página",
          info: "Visualizando {start} até {end} de {rows} registros",
          placeholder: "Pesquisar",
        },
      });

    },
  },
  watch: {
    contratos: function (newValue, oldValue) {
      if (newValue != oldValue) {
        setTimeout(() => {
          this.setDataTable();
        }, 50);
      }
    }
  }
};
</script>