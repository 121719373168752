<template>
  <div class="card mb-2" @click="onClick()">
    <div class="p-2 text-center card-header">
      <span class="text-uppercase font-weight-bold text-dark overflow-text">
        {{ cliente.nomeFantasia ?? cliente.razaoSocial }}
      </span>
      <h6 class="mt-0 font-weight-bold">
        {{ filters.currencyWithPrefix(cliente.valor) }}
      </h6>
    </div>
    <div class="p-2 text-center card-body">
      <div class="d-flex justify-content-between">
        <label>Status</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-status :ativo="cliente.ativo" size="xs" />
        </h6>
      </div>
      <hr class="mt-2 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Situação</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-situacao :status="cliente.status" size="xs" />
        </h6>
      </div>
      <hr class="mt-2 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Vertical</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-vertical :vertical="cliente.vertical" size="xs" />
        </h6>
      </div>
      <hr class="mt-2 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Tipo de Faturamento</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-tipo-faturamento :tipo-faturamento="cliente.tipoFaturamento" size="xs" />
        </h6>
      </div>
      <hr class="mt-2 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Plano</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-plano :plano="cliente.plano" :porte="cliente.porte" size="xs" />
        </h6>
      </div>
      <hr class="mt-2 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Tempo de Vida</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-tempo-vida :tempo-vida="cliente.tempoVida" size="xs" />
        </h6>
      </div>
      <hr class="mt-2 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>{{ cliente.ativo ? 'Data Contrato' : 'Data Cancelamento' }}</label>
        <h6 :class="['mb-0', 'font-weight-bolder', 'text-sm', cliente.ativo ? 'text-navy' : 'text-danger']">
          {{ filters.formatDate(cliente.ativo ? cliente.dataContrato : cliente.dataInativo, 'DD/MM/YY') }}
        </h6>
      </div>
      <hr class="mt-2 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Cidade</label>
        <h6 class="mb-0 font-weight-bolder text-sm text-uppercase">
          {{ cliente.cidade }} - {{ cliente.uf }}
        </h6>
      </div>
      <hr class="mt-2 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>ID Cliente Conexa</label>
        <h6 class="mb-0 font-weight-bolder text-sm text-uppercase">
          {{ cliente.conexaClienteId ?? '***' }}
        </h6>
      </div>
      <hr class="mt-2 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>ID Contrato Conexa</label>
        <h6 class="mb-0 font-weight-bolder text-sm text-uppercase">
          {{ cliente.conexaContratoId ?? '***' }}
        </h6>
      </div>
      <hr class="mt-2 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Empresa Conexa</label>
        <h6 class="mb-0 font-weight-bolder text-sm text-uppercase">
          {{ cliente.conexaUnidadeId == 3 ? 'BITZ' : 'BS' }}
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import BadgeStatus from "@/views/pages/components/BadgeStatus.vue";
import BadgePlano from "@/views/pages/components/BadgePlano.vue";
import BadgeVertical from "@/views/pages/components/BadgeVertical.vue";
import BadgeTipoFaturamento from "@/views/pages/components/BadgeTipoFaturamento.vue";
import BadgeTempoVida from "@/views/pages/components/BadgeTempoVida.vue";
import BadgeSituacao from "../../components/BadgeSituacao.vue";

export default {
  name: "ClienteCard",
  components: {
    BadgeStatus,
    BadgePlano,
    BadgeVertical,
    BadgeTipoFaturamento,
    BadgeTempoVida,
    BadgeSituacao,
  },
  props: {
    cliente: {
      type: Object,
      required: true,
      default: () => { }
    },
  },
  data() {
    return {

    }
  },
  methods: {
    onClick() {
      this.$router.push({
        name: 'Cliente',
        params: {
          id: this.cliente.id
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.card {
  border: 0.4px solid #212529;
  cursor: pointer;

  .card-body {
    padding: 1rem;

    label {
      text-align: left;
    }
  }

  .overflow-text {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
