<template>
  <div id="side-nav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100">
    <ul class="navbar-nav">
      <li class="nav-item" v-for="item of sideNavItems" :key="item.collapseRef">
        <side-nav-collapse :collapse-ref="item.collapseRef" :nav-text="item.navText"
          :class="getActiveRoute(item.collapseRef)">
          <template #icon>
            <i :class="item.icon"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <side-nav-item v-for="menu of item.list" :to="menu.to" :mini-icon="menu.miniIcon" :text="menu.text"
                :hidden="!menu.isVisible()" :key="menu.text" />
            </ul>
          </template>
        </side-nav-collapse>
      </li>
      
      <li class="mt-3 nav-item">
        <hr class="mt-0 horizontal dark" />
      </li>
      <li class="nav-item">
        <side-nav-collapse nav-text="Semáforos" :collapse="false" url="#" :aria-controls="''"
          collapse-ref="/public/semaforo" target="_brank">
          <template #icon>
            <i class="fas fa-traffic-light fa-2x text-success text-sm"></i>
          </template>
        </side-nav-collapse>
      </li>
      <li class="mt-3 nav-item">
        <hr class="mt-0 horizontal dark" />
      </li>
      <li class="nav-item">
        <side-nav-collapse nav-text="Sair Do Sistema" :collapse="false" url="#" :aria-controls="''" @click="signOut()"
          collapse-ref="#">
          <template #icon>
            <i class="fa fa-sign-in text-sm" :class="$store.state.sidebarType === 'bg-default' ||
        $store.state.darkMode
        ? 'text-white'
        : 'text-dark'
        "></i>
          </template>
        </side-nav-collapse>
      </li>
    </ul>
  </div>
</template>
<script>
import SideNavItem from "./SideNavItem.vue";
import SideNavCollapse from "./SideNavCollapse.vue";
import { mapMutations } from "vuex";
import { showConfirm } from "@/utils/alerts";
import { canAccessRoute } from "@/utils/security";

export default {
  name: "SideNavList",
  components: {
    SideNavItem,
    SideNavCollapse,
  },
  data() {
    return {
      sideNavItems: [
        {
          collapseRef: 'dashboards',
          navText: 'Dashboards',
          icon: 'ni ni-shop text-primary text-sm opacity-10',
          list: [
            {
              to: { name: 'Gerencial' },
              miniIcon: 'D',
              text: 'Gerencial',
              isVisible: () => this.isRouteVisible('Gerencial')
            },
            {
              to: { name: 'Financeiro' },
              miniIcon: 'D',
              text: 'Financeiro',
              isVisible: () => this.isRouteVisible('Financeiro')
            },
            {
              to: { name: 'Inadimplência' },
              miniIcon: 'D',
              text: 'Inadimplência',
              isVisible: () => this.isRouteVisible('Inadimplência')
            },
            {
              to: { name: 'Comercial' },
              miniIcon: 'C',
              text: 'Comercial',
              isVisible: () => this.isRouteVisible('Comercial')
            },
            {
              to: { name: 'Onboarding' },
              miniIcon: 'O',
              text: 'Onboarding',
              isVisible: () => this.isRouteVisible('Onboarding')
            },
            {
              to: { name: 'Produto' },
              miniIcon: 'A',
              text: 'Produto',
              isVisible: () => this.isRouteVisible('Produto')
            },
            {
              to: { name: 'Nps' },
              miniIcon: 'A',
              text: 'Nps',
              isVisible: () => this.isRouteVisible('Nps')
            },
            {
              to: { name: 'Dashboard Cancelamentos' },
              miniIcon: 'A',
              text: 'Cancelamentos',
              isVisible: () => this.isRouteVisible('Dashboard Cancelamentos')
            },
            {
              to: { name: 'Dashboard Customer Success' },
              miniIcon: 'A',
              text: 'Customer Success',
              isVisible: () => this.isRouteVisible('Dashboard Customer Success')
            }
          ]
        },
        {
          collapseRef: 'pages',
          navText: 'Cadastros',
          icon: 'ni ni-ungroup text-warning text-sm opacity-10',
          list: [
            {
              to: { name: 'Clientes' },
              miniIcon: 'C',
              text: 'Clientes',
              isVisible: () => this.isRouteVisible('Clientes')
            },
            {
              to: { name: 'Contratos' },
              miniIcon: 'C',
              text: 'Contratos',
              isVisible: () => this.isRouteVisible('Contratos')
            },
            {
              to: { name: 'Planos' },
              miniIcon: 'P',
              text: 'Planos',
              isVisible: () => this.isRouteVisible('Planos')
            },
            {
              to: { name: 'Modulos' },
              miniIcon: 'M',
              text: 'Módulos',
              isVisible: () => this.isRouteVisible('Módulos')
            },
            {
              to: { name: 'Metas' },
              miniIcon: 'M',
              text: 'Metas',
              isVisible: () => this.isRouteVisible('Metas')
            },
            {
              to: { name: 'Closers' },
              miniIcon: 'C',
              text: 'Closers',
              isVisible: () => this.isRouteVisible('Closers')
            },
            {
              to: { name: 'Implantadores' },
              miniIcon: 'I',
              text: 'Implantadores',
              isVisible: () => this.isRouteVisible('Implantadores')
            },
            {
              to: { name: 'Customer Successes' },
              miniIcon: 'CS',
              text: 'Customer Success',
              isVisible: () => this.isRouteVisible('Customer Successes')
            },
            {
              to: { name: 'Notas de Versão' },
              miniIcon: 'CS',
              text: 'Notas de Versão',
              isVisible: () => this.isRouteVisible('Notas de Versão')
            }
          ]
        },
        {
          collapseRef: 'processes',
          navText: 'Processos',
          icon: 'ni ni-ui-04 text-info text-sm opacity-10',
          list: [
            {
              to: { name: 'Upsells' },
              miniIcon: 'U',
              text: 'Upsell',
              isVisible: () => this.isRouteVisible('Upsells')
            },
            {
              to: { name: 'Upsell Modulos' },
              miniIcon: 'A',
              text: 'UpSell Módulos',
              isVisible: () => this.isRouteVisible('Upsell Modulos')
            },
            {
              to: { name: 'Cancelamentos' },
              miniIcon: 'C',
              text: 'Cancelamentos',
              isVisible: () => this.isRouteVisible('Cancelamentos')
            },
            {
              to: { name: 'Kanban Cancelamentos' },
              miniIcon: 'C',
              text: 'Kanban Cancelamentos',
              isVisible: () => this.isRouteVisible('Cancelamentos')
            }
          ]
        }
      ]
    }
  },
  methods: {
    ...mapMutations(['clearUser']),
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr.at(2);
    },
    getActiveRoute(name) {
      const activeRoute = this.getRoute();
      return name == activeRoute ? 'active' : '';
    },
    async signOut() {
      const confirm = await showConfirm('Atenção', 'Deseja realmente sair?');

      if (!confirm) {
        return
      }

      this.clearUser();

      localStorage.removeItem('access_token');

      this.$router.push({ name: 'Signin' });
    },
    isRouteVisible(name) {
      return canAccessRoute({ name });
    }
  }
};
</script>
