<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="mx-auto col-8">
        <div class="card">
          <div class="card-header">
            <h6 class="mb-0">{{ id == 'new' ? 'Nova meta' : 'Editar meta' }}</h6>
            <p class="mb-0 text-sm">Criar/Editar meta</p>
          </div>
          <div class="card-body">
            <form role="form" @submit.prevent="onSubmit">
              <div class="row mb-1">
                <div class="col-md-6">
                  <label>Data</label>
                  <datepicker v-model="v$.meta.data.$model" minimum-view="month"
                    class="form-control text-bold text-center" :locale="locale" input-format="MM/yyyy" />

                  <div class="input-errors" v-for="(error, index) of v$.meta.data.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label>Valor Total</label>
                  <money3 v-model="v$.meta.valorTotal.$model" class="form-control text-right" v-bind="config" />
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-md-6">
                  <label>MRR</label>
                  <money3 v-model="v$.meta.valorMrr.$model" class="form-control text-right" v-bind="config" />
                </div>
                <div class="col-md-6">
                  <label>ARR</label>
                  <money3 v-model="v$.meta.valorArr.$model" class="form-control text-right" v-bind="config" />
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-md-4">
                  <label>UpSell</label>
                  <money3 v-model="v$.meta.valorUpSell.$model" class="form-control text-right" v-bind="config" />
                </div>
                <div class="col-md-4">
                  <label>Consultoria</label>
                  <money3 v-model="v$.meta.valorConsultoria.$model" class="form-control text-right" v-bind="config" />
                </div>
                <div class="col-md-4">
                  <label>Churn</label>
                  <money3 v-model="v$.meta.valorChurn.$model" class="form-control text-right" v-bind="config" />
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <router-link :to="{ name: 'Metas' }">
                  <argon-button type="button" color="dark" class="my-4 mb-2">
                    Voltar
                  </argon-button>
                </router-link>
                <argon-button type="submit" color="success" variant="gradient" class="my-4 mb-2">
                  SALVAR
                </argon-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import ArgonButton from "@/components/ArgonButton.vue";
import { successToast, errorToast } from '@/utils/alerts';
import Datepicker from 'vue3-datepicker'
import api from './../../services/api'
import { ptBR } from 'date-fns/locale'
import { MONEY_CONFIG } from './../../utils/number'
import moment from 'moment';

export default {
  name: "Meta",
  components: {
    ArgonButton,
    Datepicker
  },
  created() {
    this.id = this.$route.params.id;

    if (this.id == 'new') {
      return;
    }

    this.fetchData();
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      id: null,
      locale: ptBR,
      config: MONEY_CONFIG,
      meta: {
        id: null,
        data: new Date(),
        valorMrr: 0,
        valorArr: 0,
        valorConsultoria: 0,
        valorUpSell: 0,
        valorChurn: 0,
        valorTotal: 0,
      }
    }
  },
  validations() {
    return {
      meta: {
        data: {
          required: helpers.withMessage('Data é obrigatória', required),
        },
        valorMrr: {},
        valorArr: {},
        valorConsultoria: {},
        valorUpSell: {},
        valorChurn: {},
        valorTotal: {},
      },
    }
  },
  methods: {
    fetchData() {
      const { id } = this;

      api.get(`/metas/${id}`)
        .then(({ data }) => {
          const date = moment(data.data).toDate();

          this.meta = {
            ...data,
            data: date
          };
        })
        .catch(e => console.log(e));
    },
    async onSubmit() {
      const isFormValid = await this.v$.$validate();

      if (!isFormValid) {
        return;
      }

      try {
        const payload = {
          ...this.meta
        }

        await (this.id == 'new' ?
          api.post('/metas', payload) :
          api.patch(`/metas/${this.id}`, payload));

        successToast('Meta salva com sucesso.');

        this.$router.push({ name: 'Metas' });

      } catch (e) {
        console.log(e);
        const { data } = e.response;
        const message = data ? data.message : e.message;
        errorToast(message)
      }
    },
  }
};
</script>
